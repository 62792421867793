// load ElementInternals polyfills if not supported by the browser
const supportElementInternals = (
  typeof window.ElementInternals === 'function'
  && typeof HTMLElement.prototype.attachInternals === 'function'
);

if (!supportElementInternals) {
  console.log('loading ElementInternals polyfill');
  require('element-internals-polyfill');
}
